<template>
  <div class="main main--internal">
    <div class="page-header__container">
      <h1 class="page-header">Политика самозанятого Магомедова Арсена Абдуллаевича в отношении обработки персональных данных, в т.ч. на сервисе-сайте Судбот (sudbot.ru)</h1>
    </div>
    <article class="article">
      <div class="article__block">
        <div class="article__content">
          <div class="article__content__text">
            УТВЕРЖДЕНО<br>
            Магомедов Арсен Абдуллаевич<br>
            "24" марта 2022 г.<br>
            ПОЛИТИКА самозанятого Магомедова Арсена Абдуллаевича В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ, в т.ч. на сервисе-сайте Судбот (sudbot.ru)<br><br>
            1. ОБЩИЕ ПОЛОЖЕНИЯ<br><br>
            1.1. Настоящий документ (далее - Политика) определяет политику в отношении обработки персональных данных самозанятого Магомедова Арсена Абдуллаевича (далее - Оператор или Компания), в т.ч. на сервисе-сайте Судбот (sudbot.ru).<br>
            1.2. Настоящая Политика разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных" (далее - Закон о персональных данных).<br>
            1.3. Понятия, содержащиеся в ст. 3 Закона о персональных данных, используются в настоящей Политике с аналогичным значением.<br>
            1.4. Действие настоящей Политики распространяется на все операции, совершаемые Оператором с персональными данными с использованием средств автоматизации или без их использования.<br>
            1.5. Основные права и обязанности Оператора.<br>
            1.5.1. Оператор имеет право:<br>
            • получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;<br>
            • требовать от субъекта персональных данных своевременного уточнения предоставленных персональных данных.<br>
            1.5.2. Оператор обязан:<br>
            • обрабатывать персональные данные в порядке, установленном действующим законодательством РФ;<br>
            • рассматривать обращения субъекта персональных данных (его законного представителя) по вопросам обработки персональных данных и давать мотивированные ответы;<br>
            • предоставлять субъекту персональных данных (его законному представителю) возможность безвозмездного доступа к его персональным данным;<br>
            • принимать меры по уточнению, уничтожению персональных данных субъекта персональных данных в связи с его (его законного представителя) обращением с законными и обоснованными требованиями;<br>
            • организовывать защиту персональных данных в соответствии с требованиями законодательства РФ.<br>
            1.6. Основные права и обязанности субъектов персональных данных:<br>
            1.6.1. Субъекты персональных данных имеют право:<br>
            • на полную информацию об их персональных данных, обрабатываемых Оператором;<br>
            • на доступ к их персональным данным, включая право на получение копии любой записи, содержащей их персональные данные, за исключением случаев, предусмотренных федеральным законом;<br>
            • на уточнение их персональных данных, их блокирование или уничтожение в случаях, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;<br>
            • на отзыв согласия на обработку персональных данных;<br>
            • на принятие предусмотренных законом мер по защите своих прав;<br>
            • на осуществление иных прав, предусмотренных законодательством РФ.<br>
            1.6.2. Субъекты персональных данных обязаны:<br>
            • предоставлять Оператору достоверные данные о себе;<br>
            • предоставлять документы, содержащие персональные данные в объеме, необходимом для цели обработки;<br>
            • сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных;<br>
            • в случае, передачи Оператору персональных данных третьих лиц получать их согласие на это и нести ответственность перед Оператором за нарушение этого обязательства.<br>
            1.6.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.<br><br>
            2. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ<br><br>
            2.1. Оператор может обрабатывать персональные данные следующих субъектов персональных данных:<br>
            • клиенты и контрагенты Компании (физические лица);<br>
            • представители/работники клиентов и контрагентов Компании (юридических лиц);<br>
            • посетители сайта (сайтов) Компании (далее - Сайт и Сайты), в т.ч. сервиса – сайта Судбот (sudbot.ru).<br>
            2.2. К персональным данным, обрабатываемым Оператором, относятся:<br>
            • фамилия, имя, отчество субъекта персональных данных;<br>
            • место проживания (регион/город);<br>
            • дата и место рождения;<br>
            • дата, номер, серии и иные данные документов;<br>
            • адреса, номера (кадастровые, государственные и пр) и прочие данные имущества;<br>
            • специальность/область профессиональных интересов;<br>
            • номер мобильного телефона;<br>
            • адрес электронной почты (e-mail);<br>
            • история запросов и просмотров на Сайте и его сервисах (для посетителей Сайтов);<br>
            • персональные данные третьих лиц, вносимая субъектом персональных данных;<br>
            • иная информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (приведенный перечень может сокращаться или расширяться в зависимости от конкретного случая и целей обработки), в том числе любая соответствующая информация, вносимая субъектом персональных данных.<br>
            2.3. Оператор обеспечивает соответствие содержания и объема обрабатываемых персональных данных заявленным целям обработки и, в случае необходимости, принимает меры по устранению их избыточности по отношению к заявленным целям обработки.<br>
            2.4. Оператор обрабатывает биометрические персональные данные при условии письменного согласия соответствующих субъектов персональных данных, а также в иных случаях, предусмотренных законодательством Российской Федерации.<br>
            2.5. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.<br>
            2.6. Трансграничная передача персональных данных Оператором не осуществляется.<br><br>
            3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ<br><br>
            3.1. Персональные данные обрабатываются Оператором в следующих целях:<br>
            • оказания услуг субъекту персональных данных на Сайте (Сайтах), в т.ч. на сервисе-сайте Судбот (sudbot.ru).<br>
            • заключение с субъектами персональных данных любых договоров и их дальнейшего исполнения;<br>
            • проведение Оператором акций, опросов, интервью, тестирований и исследований на Сайтах;<br>
            • предоставление субъектам персональных данных сервисов и услуг Компании, а также информации о разработке Компанией новых продуктов и услуг, в том числе рекламного характера;<br>
            • обратная связь с субъектами персональных данных, в том числе обработка их запросов и обращений, информирование о работе Сайта (Сайтов);<br>
            • контроль и улучшение качества услуг и сервисов Компании, в том числе предложенных на Сайте (Сайтах);<br>
            • формирование статистической отчетности;<br>
            • осуществление хозяйственной деятельности;<br>
            • осуществление иных функций, полномочий и обязанностей, возложенных на Оператора законодательством РФ.<br><br>
            4. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ<br><br>
            4.1. Правовыми основаниями обработки персональных данных Оператором являются:<br>
            • Конституция РФ;<br>
            • Трудовой кодекс РФ;<br>
            • Гражданский кодекс РФ;<br>
            • Федеральный закон от 27 июля 2006 г. N 149-ФЗ "Об информации, информационных технологиях и о защите информации";<br>
            • Закон РФ от 27 декабря 1991 г. N 2124-1 "О средствах массовой информации";<br>
            • Федеральный закон от 26 декабря 2008 г. N 294-ФЗ "О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля";<br>
            • Указ Президента РФ от 6 марта 1997 г. N 188 "Об утверждении перечня сведений конфиденциального характера";<br>
            • Постановление Правительства Российской Федерации от 06 июля 2008 г. N 512 "Об утверждении требований к материальным носителям биометрических персональных данных и технологиям хранения таких данных вне информационных систем персональных данных";<br>
            • Постановление Правительства Российской Федерации от 15 сентября 2008 г. N 687 "Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации";<br>
            • Постановление Правительства Российской Федерации от 1 ноября 2012 г. N 1119 "Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных";<br>
            • Приказ Роскомнадзора от 5 сентября 2013 г. N 996 "Об утверждении требований и методов по обезличиванию персональных данных";<br>
            • Приказ ФСТЭК России от 18 февраля 2013 г. N 21 "Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных";<br>
            • уставные документы Оператора;<br>
            • договоры, заключаемые между Оператором и субъектами персональных данных;<br>
            • согласия субъектов персональных данных на обработку персональных данных;<br>
            • иные основания, когда согласие на обработку персональных данных не требуется в силу закона.<br><br>
            5. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ<br><br>
            5.1. Обработка персональных данных Оператором осуществляется следующими способами:<br>
            • неавтоматизированная обработка персональных данных;<br>
            • автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой;<br>
            • смешанная обработка персональных данных.<br>
            5.2. Перечень действий, совершаемых Оператором с персональными данными: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение, а также осуществление любых иных действий в соответствии с действующим законодательством РФ.<br>
            5.3. Обработка персональных данных осуществляется Оператором при условии получения согласия субъекта персональных данных (далее - Согласие), за исключением установленных законодательством РФ случаев, когда обработка персональных данных может осуществляться без такого Согласия.<br>
            5.4. Субъект персональных данных принимает решение о предоставлении его персональных данных и дает Согласие свободно, своей волей и в своем интересе.<br>
            5.5. Согласие дается в любой позволяющей подтвердить факт его получения форме. В предусмотренных законодательством РФ случаях Согласие оформляется в письменной форме.<br>
            5.6. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия Согласия или отзыв Согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.<br>
            5.7. Согласие может быть отозвано путем письменного уведомления, направленного в адрес Компании заказным почтовым отправлением.<br>
            5.8. Оператор для достижения целей обработки с согласия субъекта персональных данных, выраженного в соответствующем его действии на сайте sudbot.ru (например, клик субъекта персональных данных на кнопку «Передать мои данные партнеру для оказания офлайн услуг» или иную подобную кнопку) вправе передавать персональные данные посетителей Сайтов партнерам Оператора.<br>
            5.9. Оператор при обработке персональных данных принимает или обеспечивает принятие необходимых правовых, организационных и технических мер для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.<br>
            5.10. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, в течение срока не дольше, чем этого требуют цели обработки персональных данных, кроме случаев, когда срок хранения персональных данных установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Для субъектов персональных данных, являющихся пользователями сервиса-сайта Судбот (sudbot.ru) хранение персональных данных осуществляется до момента прекращения удаления учетной записи субъекта персональных данных на этом сервисе-сайте.<br>
            5.11. При осуществлении хранения персональных данных Оператор использует базы данных, находящиеся на территории РФ.<br><br>
            6. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ НА ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ<br><br>
            6.1. В случае подтверждения факта неточности персональных данных или неправомерности их обработки персональные данные подлежат их актуализации Оператором, или их обработка должна быть прекращена соответственно.<br>
            6.2. Факт неточности персональных данных или неправомерности их обработки может быть установлен либо субъектом персональных данных, либо компетентными государственными органами РФ.<br>
            6.3. По письменному запросу субъекта персональных данных или его представителя Оператор обязан сообщить информацию об осуществляемой им обработке персональных данных указанного субъекта. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта персональных данных и его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись субъекта персональных данных или его представителя. Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством РФ.<br>
            6.4. Если в запросе субъекта персональных данных не отражены все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.<br>
            6.5. В порядке, предусмотренном п. 6.3, субъект персональных данных вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.<br>
            6.6. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных Согласия, персональные данные подлежат уничтожению, если:<br>
            • Оператор не вправе осуществлять обработку без Согласия субъекта персональных данных;<br>
            • иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;<br>
            • иное не предусмотрено иным соглашением между Оператором и субъектом персональных данных.<br>
            Для субъектов персональных данных, пользователей сервиса-сайта Судбот (sudbot.ru) достижение целей обработки персональных данных наступает в момент удаления учетной записи субъекта персональных данных на этом сервисе-сайте.<br>
            <br><br>
            7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ<br><br>
            7.1. Все отношения, касающиеся обработки персональных данных, не получившие отражения в настоящей Политике, регулируются согласно положениям законодательства РФ.<br>
            7.2. Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на Сайте по адресу: https://sudbot.ru/<br>
            <br>
             © Магомедов Арсен Абдуллаевич, 2020-2023<br><br>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ConfidentialityPage'
}
</script>
